<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px">
          <div class="buscador3">
            <maca-input-delay
              v-model="descripcion"
              icon="el-icon-search"
              placeholder="Buscar provincia"
            ></maca-input-delay>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Provincia</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <!-- Provincias -->
          <el-table-column type="expand">
            <template slot-scope="props">
              <!-- Localidades -->
              <el-table :data="props.row.detalleSubareas" stripe>
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <!-- Barrio -->
                    <el-table :data="props.row.detalleSeccion" stripe>
                      <el-table-column prop="descripcion" label="Barrios">
                      </el-table-column>
                      <el-table-column label="Editar" width="70">
                        <template slot-scope="props">
                          <el-button
                            type="primary"
                            circle
                            @click="
                              $refs.modalModificarSeccion.abrir(props.row.id)
                            "
                            :disabled="!$store.getters.tienePermiso('M_USE')"
                          >
                            <i class="el-icon-edit"></i>
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column label="Borrar" width="70">
                        <template slot-scope="props">
                          <el-button
                            type="danger"
                            @click="eliminarSeccion(props.row.id)"
                            circle
                            :disabled="!$store.getters.tienePermiso('B_USE')"
                          >
                            <i class="el-icon-delete"></i>
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="descripcion" label="Localidades">
                </el-table-column>
                <el-table-column width="200px" style="margin-right: 0px">
                  <template slot-scope="props">
                    <el-button
                      class="botonmasIcono"
                      icon="el-icon-plus"
                      type="primary"
                      round
                      style="margin-right: 0px"
                      @click="
                        $refs.modalNuevoSeccion.abrir(
                          props.row.id,
                          props.row.descripcion
                        )
                      "
                    >
                      Barrio
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Editar" width="70">
                  <template slot-scope="props">
                    <el-button
                      type="primary"
                      circle
                      @click="$refs.modalModificarSubarea.abrir(props.row.id)"
                      :disabled="!$store.getters.tienePermiso('M_USE')"
                    >
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Borrar" width="70">
                  <template slot-scope="props">
                    <el-button
                      type="danger"
                      @click="eliminarSubarea(props.row.id)"
                      circle
                      :disabled="!$store.getters.tienePermiso('B_USE')"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
            label="Provincias"
            prop="descripcion"
          ></el-table-column>
          <el-table-column width="200px" style="margin-right: 0px">
            <template slot-scope="props">
              <el-button
                class="botonmasIcono"
                icon="el-icon-plus"
                type="primary"
                round
                style="margin-right: 0px"
                @click="
                  $refs.modalNuevoSubarea.abrir(
                    props.row.id,
                    props.row.descripcion
                  )
                "
              >
                Localidad
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo-subarea
      ref="modalNuevoSubarea"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo-subarea>
    <modal-nuevo-seccion
      ref="modalNuevoSeccion"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo-seccion>
    <modal-modificar-subarea
      ref="modalModificarSubarea"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar-subarea>
    <modal-modificar-seccion
      ref="modalModificarSeccion"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar-seccion>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalModificarSubarea from "./modales/modificar_subarea";
import ModalModificarSeccion from "./modales/modificar_seccion.vue";
import ModalNuevoSubarea from "./modales/nuevo_subarea";
import ModalNuevoSeccion from "./modales/nuevo_seccion";

export default {
  name: "area",
  components: {
    ModalNuevo,
    ModalModificar,
    ModalModificarSubarea,
    ModalNuevoSubarea,
    ModalNuevoSeccion,
    ModalModificarSeccion,
  },
  data() {
    return {
      urlTabla: "/area/obtenerTodosConSubareaSeccion",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      descripcion: "",
      filtroNombre: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la provincia."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/area/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Provincia borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async eliminarSubarea(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la localidad."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/subarea/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Localidad borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async eliminarSeccion(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el barrio."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/seccion/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Barrio borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    descripcion() {
      this.paramsTabla = { descripcion: this.descripcion };
      this.actualizarTabla = true;
    },
  },
};
</script>
