<template>
  <div>
    <maca-modal
      :titulo="'Nuevo barrio de ' + titulo"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Descripción" prop="descripcion">
          <el-input v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item label="Ubicación">
          <el-row :gutter="5">
            <el-col :span="18" :offset="0"
              ><GmapAutocomplete
                @place_changed="setPlace"
                class="buscadorGoogle"
            /></el-col>
            <el-col :span="4" :offset="0">
              <el-button type="primary" plain @click="addMarker">
                Buscar
              </el-button></el-col
            >
          </el-row>
        </el-form-item>

        <el-form-item>
          <GmapMap
            :center="form.center"
            :zoom="17"
            style="width:100%;  height: 200px;"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in form.markers"
              :position="m.position"
              @click="form.center = m.position"
            />
          </GmapMap>
        </el-form-item>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-subarea",
  props: {
    formChico: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: null,
      titulo: null,
      form: {
        descripcion: null,
        latitud: null,
        longitud: null,
        currentPlace: null,
        markers: [],
        places: [],
        ciudad: "",
        center: { lat: 0, lng: 0 },
      },

      impedirClose: false,
      formRules: {
        descripcion: [
          {
            required: true,
            message: "Por favor introduzca la descripción.",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    setTimeout(() => {
      if (this.formChico != null) {
        this.form = this.formChico;
      }
    }, 150);
  },
  methods: {
    cerrar() {
      this.$refs.modal.cerrar();
    },

    abrir(id, descripcion) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.titulo = descripcion;
    },
    setPlace(place) {
      this.form.currentPlace = place;
    },
    addMarker() {
      if (this.form.currentPlace) {
        const position = {
          lat: this.form.currentPlace.geometry.location.lat(),
          lng: this.form.currentPlace.geometry.location.lng(),
        };
        this.latitud = this.form.currentPlace.geometry.location.lat();
        this.longitud = this.form.currentPlace.geometry.location.lng();
        this.form.markers = [];
        this.form.markers.push({ position: position });
        this.form.places.push(this.form.currentPlace);
        this.form.center = position;
        this.form.currentPlace = null;
      }
    },

    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        subareaID: this.id,
        descripcion: this.form.descripcion,
        latitud: this.latitud,
        longitud: this.longitud,
      };

      let respuestaApi = await this.$maca.api.post("/seccion/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Barrio creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 9999;
}

.buscadorGoogle {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>
